import React from 'react';

import Layout from '../components/layout';
import Component404 from '../components/404';
import Trustpilot from '../components/Trustpilot';
import SubscribeForm from '../components/SubscribeForm';
import WhyBookWithUs from '../components/WhyBookWithUs/WhyBookWithUs';

class Page404 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: props.phoneNumber,
      isFlightsLocation: false
    };
  }
  componentDidMount() {
    window.location.pathname.includes('/flights/flights-') &&
      this.setState({ isFlightsLocation: true });
  }
  render() {
    return (
      <Layout
        isErrorPage
        passPhoneNumber={phoneNumber => {
          this.setState({
            phoneNumber
          });
        }}
      >
        {this.state.isFlightsLocation ? (
          <>
            <WhyBookWithUs />
            <SubscribeForm />
            <Trustpilot />
          </>
        ) : (
          <div className="main">
            <Component404
              phoneNumber={this.state.phoneNumber}
              pageLocation={this.props.location}
            />
          </div>
        )}
      </Layout>
    );
  }
}

export default Page404;
